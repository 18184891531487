// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--DcrIU";
export var btnBlock = "banner-module--btnBlock--8C0Iz";
export var container = "banner-module--container--DsQFz";
export var hideMobBlock = "banner-module--hideMobBlock--X3u4h";
export var img = "banner-module--img--H78XK";
export var mobBtn = "banner-module--mobBtn--3OJMN";
export var red = "banner-module--red--ivisP";
export var secondContainer = "banner-module--secondContainer--x7pvM";
export var section = "banner-module--section--5VGWB";
export var text = "banner-module--text--Xy+wC";